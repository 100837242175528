<template>
  <v-dialog
    v-bind="$attrs"
    ref="dialog"
    persistent
    width="800"
    content-class="z-index-5"
  >
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <CardHeader>
        <template slot="left">
          <div class="pa-5 font-18px" v-if="editMode">
            {{ $t("page_payable_edit_dialog_title") }}
          </div>
          <div class="pa-5 font-18px" v-else>
            {{ $t("page_payable_title") }}
          </div>
        </template>
      </CardHeader>
      <v-card-text>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="submit" lazy-validation autocomplete="off">
            <!-- the radio buttons -->
            <v-container>
              <v-row>
                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_accrual_date')"
                  :cols="6"
                >
                  <DatePicker
                    v-model="form.accrual_date"
                    @input="formatAccuralDate"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="font-12px"
                        v-on="on"
                        v-bind="attrs"
                        v-model="actual_accural_date"
                        append-icon="mdi-menu-down"
                        readonly
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </template>
                  </DatePicker>
                </CreateFormLabel>

                <v-col cols="6"></v-col>

                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_suppliers')"
                >
                  <v-select
                    class="font-12px"
                    v-model="form.partner_id"
                    :items="suppliers"
                    hide-details="auto"
                    dense
                  ></v-select>
                </CreateFormLabel>

                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_sorting')"
                >
                  <v-select
                    class="font-12px"
                    v-model="form.surveyed_subjects"
                    :items="surveyedSubjectsWithIndex"
                    hide-details="auto"
                    dense
                  ></v-select>
                </CreateFormLabel>

                <!-- deposit / withdrawl -->
                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_deposit')"
                >
                  <v-text-field
                    class="font-12px"
                    v-model="form.purchase_amount"
                    inputmode="tel"
                    type="number"
                    prefix="¥"
                    hide-details="auto"
                    dense
                  ></v-text-field>
                </CreateFormLabel>

                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_tax_division')"
                >
                  <v-radio-group
                    row
                    v-model="form.taxonomy"
                    hide-details="auto"
                    dense
                    class="mt-0"
                  >
                    <v-radio label="8%" :value="2"></v-radio>
                    <v-radio label="10%" :value="1"></v-radio>
                  </v-radio-group>
                </CreateFormLabel>

                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_memo')"
                  :cols="12"
                >
                  <v-textarea
                    v-model="form.notes"
                    class="pa-0 font-12px"
                    rows="2"
                    hide-details="auto"
                    dense
                  ></v-textarea>
                </CreateFormLabel>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions class="pb-12">
            <template v-if="editMode">
              <v-btn color="#790000" text @click="deleteConfirmation">
                削除
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2 text-light-grey"
              @click="$emit('close-dialog')"
              width="101px"
              height="31px"
              depressed
              color="transparent"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mx-2"
              width="101px"
              height="31px"
              color="primary"
              @click="submit"
              :disabled="invalid"
              :loading="loading"
            >
              {{
                editMode
                  ? $t("keep")
                  : $t("page_cashbook_confirmation_register")
              }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
import CreateFormLabel from "@/components/admin/ui/FormLabel";
import DatePicker from "@/components/admin/ui/DatePicker";
import dayjs from "@/plugins/dayjs";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "AccountsPayableCreateDialog",
  components: {
    CardHeader,
    CreateFormLabel,
    DatePicker
  },
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "getShop",
      "getSuppliers",
      "getAccountsPayable",
      "getMasterData"
    ]),
    editMode() {
      return this.edit;
    },
    suppliers() {
      return this.getSuppliers.map(supplier => {
        return {
          value: supplier.id,
          text: supplier.display_name
        };
      });
    },
    payers() {
      let MPUsers =
        this.getShop.user_shop.filter(user => user.user_role !== "S") || [];
      let SUsers = this.getShop.shared_account?.shared_accounts || [];
      return [...MPUsers, ...SUsers].map(user => {
        return {
          value: user.id,
          text: user?.user_role
            ? `${user.user?.surname} ${user.user?.name}`
            : `${user.name}`,
          role: user.user_role || "S"
        };
      });
    },
    surveyedSubjectsWithIndex() {
      return this.getMasterData.surveyed_subjects.map((item, index) => {
        return {
          value: index,
          text: item.value
        };
      });
    }
  },
  data() {
    return {
      loading: false,
      actual_accural_date: "",
      form: {
        accrual_date: dayjs().format("YYYY-MM-DD"),
        partner_id: null,
        surveyed_subjects: null,
        purchase_amount: 0,
        taxonomy: 2,
        notes: ""
      },
      withdrawalForm: [
        "withdrawal_or_deposit",
        "accrual_date",
        "purchase_amount",
        "notes"
      ]
    };
  },
  created() {
    this.$store.dispatch("SUPPLIER_GET_ALL", { shop_id: this.getShop.id });
  },
  methods: {
    ...mapMutations([
      "showModal",
      "hideModal",
      "startModalLoading",
      "stopModalLoading"
    ]),
    resetForm() {
      this.form = {
        accrual_date: dayjs().format("YYYY-MM-DD"),
        partner_id: null,
        surveyed_subjects: null,
        purchase_amount: 0,
        taxonomy: 2,
        notes: ""
      };
    },
    formatAccuralDate(input) {
      this.actual_accural_date = dayjs(input).format("YYYY年MM月DD日");
    },
    fillEditForm() {
      if (this.editMode) {
        for (const [index] of Object.entries(this.form)) {
          if (this.getAccountsPayable.hasOwnProperty(index)) {
            this.form[index] = this.getAccountsPayable[index];
          }
        }
        this.actual_accural_date = dayjs(this.form.accrual_date).format(
          "YYYY年MM月DD日"
        );
        this.form.surveyed_subjects = parseInt(this.form.surveyed_subjects);
      }
    },
    deleteConfirmation() {
      this.showModal({
        title: "",
        text: "買掛情報を\n削除してもよろしいですか？",
        action: {
          process: {
            color: "primary",
            text: "削除"
          },
          cancel: {
            color: "secondary",
            text: "キャンセル"
          }
        },
        func: this.processDelete
      });
    },
    processDelete() {
      this.$store
        .dispatch("ACCOUNTS_PAYABLE_DELETE", {
          shop_id: this.getShop.id,
          accounts_payable_id: this.getAccountsPayable.id
        })
        .then(() => {
          this.hideModal();
          this.stopModalLoading();
          this.$emit("close-dialog");
          this.$emit("reload-items");
        })
        .catch(() => {
          this.stopModalLoading();
        });
    },
    submit() {
      this.$refs.observer.validate().then(async success => {
        if (!success) {
          return;
        }
        this.loading = true;

        let params = {};
        let shop_id = this.getShop.id;
        this.form.surveyed_subjects = this.form.surveyed_subjects.toString();
        for (const [index, value] of Object.entries(this.form)) {
          if (value) params[index] = value;
        }
        params.user_shop_id = this.user.id;
        await this.$store
          .dispatch(
            this.editMode
              ? "ACCOUNTS_PAYABLE_UPDATE"
              : "ACCOUNTS_PAYABLE_REGISTER",
            {
              params: params,
              shop_id: shop_id,
              ...(this.editMode
                ? { accounts_payable_id: this.getAccountsPayable.id }
                : {})
            }
          )
          .then(() => {
            this.$emit("reload-items");
            this.resetForm();
            this.$emit("close-dialog");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.z-index-5 {
  z-index: 5;
}
</style>
