<template>
  <div class="">
    <Parent v-if="!partner" />
    <CreateOrEdit
      ref="editDialog"
      v-model="editDialog"
      edit
      @close-dialog="editDialog = false"
      @reload-items="getDataFromApi"
    />
    <CreateOrEdit
      ref="createDialog"
      v-model="createDialog"
      @close-dialog="createDialog = false"
      @reload-items="getDataFromApi"
    >
    </CreateOrEdit>
    <Table
      class="mt-3"
      :headers="headers"
      :items="items"
      :total-records="getAccountsPayablesPagination.records_total"
    >
      <template v-slot:top>
        <CardHeader>
          <template slot="left">
            <div class="pl-5 pt-3 pb-2 d-flex align-center justify-center">
              <v-card color="#525252" flat height="29px" width="83px">
                <div class="d-flex justify-center align-center">
                  <v-icon color="white" size="17">$accountPayableWhite</v-icon>
                  <div class="font-18px white--text pl-2">
                    {{ $t("page_payable_title") }}
                  </div>
                </div>
              </v-card>
              <div class="dropdown-main-div mx-4">
                <v-select
                  dense
                  solo
                  flat
                  :items="months"
                  hide-details
                  append-icon="$purpleDownArrow"
                  background-color="#D8D5FC"
                  v-model="selectedMonth"
                  @change="getDataFromApi"
                  height="29"
                >
                  <template v-slot:selection="{ item }">
                    <span class="primary--text font-14px">
                      {{ item }}
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </template>
          <template v-if="!partner" slot="right">
            <v-btn
              :ripple="false"
              color="primary"
              text
              @click="createDialog = true"
            >
              <v-icon left>mdi-plus</v-icon>新規登録
            </v-btn>
          </template>
        </CardHeader>
      </template>
      <template v-slot:item.day="{ item }">
        <div>{{ item.day }}</div>
      </template>
      <template v-slot:item.surveyed_subjects="{ item }">
        <div>
          {{ getSurveyedObject(item.surveyed_subjects) }}
        </div>
      </template>
      <template v-slot:item.sorting="{ item }">
        <div>{{ getSurveyedObject(item.surveyed_subjects) }}</div>
      </template>
      <template v-slot:item.suppliers="{ item }">
        <div>{{ item.suppliers }}</div>
      </template>
      <template v-slot:item.purchase_amount="{ item }">
        <div>{{ item.purchase_amount | formatMoney }}</div>
      </template>
      <template v-slot:item.tax_rate="{ item }">
        <div>{{ taxRateFormat(item.tax_rate) }}</div>
      </template>
      <template v-slot:item.memo="{ item }">
        <div>{{ item.memo }}</div>
      </template>
      <template v-slot:item.user="{ item }">
        <div>{{ item.user }}</div>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn @click="openEditDialog(item)" small icon :ripple="false">
          <v-icon>$edit</v-icon>
        </v-btn>
      </template>
    </Table>
  </div>
</template>
<script>
import Table from "@/components/admin/partials/Table/Table";
import CardHeader from "@/components/admin/ui/CardHeader";
import CreateOrEdit from "@/components/admin/partials/AccountPayable/CreateEditDialog";
import Parent from "@/views/admin/Store/Index";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "PayableAccountsIndex",
  components: {
    Table,
    CardHeader,
    CreateOrEdit,
    Parent
  },
  props: {
    partner: {
      required: false,
      default: null
    }
  },
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters([
      "getShop",
      "getAccountsPayable",
      "getAccountsPayables",
      "getAccountsPayablesPagination",
      "getMasterData"
    ]),
    surveyedSubjects() {
      return this.getMasterData.surveyed_subjects;
    },
    id() {
      return this.$route.params.shop_id;
    },
    items() {
      return this.getAccountsPayables.map(account => {
        return {
          id: account.id,
          day: dayjs(account.accrual_date).format("MM/DD (dd)"),
          surveyed_subjects: account.surveyed_subjects,
          suppliers: account.partner?.display_name,
          purchase_amount: account.purchase_amount,
          tax_rate: account.taxonomy ? account.taxonomy.toString() : "",
          memo: account.notes,
          user: this.getAccountUser(account)
        };
      });
    },
    months() {
      let now = dayjs();
      let months = [];
      let begin = now.subtract(1, "year");
      let end = now.add(1, "year");

      while (begin.isSameOrBefore(end)) {
        months.push(begin.format("YYYY/MM"));

        begin = begin.add(1, "month");
      }
      return months;
    },
    headers() {
      let tableHeaders = [
        {
          text: this.$t("page_cashbook_table_header_day"),
          value: "day",
          width: 129,
          sortable: false
        },
        {
          text: this.$t("page_cashbook_table_header_sorting"),
          value: "surveyed_subjects",
          sortable: false,
          width: 129
        },
        {
          text: this.$t("page_cashbook_table_header_suppliers"),
          value: "suppliers",
          sortable: false,
          width: 200
        },
        {
          text: this.$t("page_cashbook_table_header_withdrawl_amount"),
          value: "purchase_amount",
          sortable: false,
          width: 200
        },
        {
          text: this.$t("page_cashbook_table_header_tax_rate"),
          value: "tax_rate",
          sortable: false,
          width: 100
        },
        {
          text: this.$t("page_cashbook_table_header_memo"),
          value: "memo",
          sortable: false,
          width: 200
        },
        {
          text: "",
          value: "edit",
          align: "right",
          sortable: false
        }
      ];
      if (this.partner) {
        tableHeaders.splice(2, 1);
      }
      return tableHeaders;
    }
  },
  data() {
    return {
      selectedMonth: "",
      editDialog: false,
      createDialog: false
    };
  },
  mounted() {
    this.selectedMonth = dayjs().format("YYYY/MM");
    this.getDataFromApi();
  },
  methods: {
    taxRateFormat(taxRate) {
      switch (taxRate) {
        case "1":
          return "10%";
        case "2":
          return "8%";
        default:
          return "";
      }
    },
    getSurveyedObject(val) {
      const temp = this.surveyedSubjects.find(element => {
        return parseInt(val) === element.id - 1;
      });
      if (temp !== undefined) return temp.value;
    },
    async getDataFromApi() {
      let date = dayjs(this.selectedMonth, "YYYY/MM");
      let year = date.format("YYYY");
      let month = date.format("MM");
      await this.$store.dispatch("ACCOUNTS_PAYABLE_GET_ALL", {
        id: this.id,
        param: {
          paginate: 25,
          year: year,
          month: month,
          partner_id: this.partner
        }
      });
    },
    getAccountUser(account) {
      if (account?.user_shop_id) {
        return `${account?.user_shop?.user?.surname ?? ""} ${account?.user_shop
          ?.user?.name ?? ""}`;
      } else if (account?.shared_account_id) {
        return `${account?.shared_account?.name ?? "-"}`;
      }
      return "";
    },
    openEditDialog(item) {
      this.$store
        .dispatch("ACCOUNTS_PAYABLE_GET", {
          id: this.getShop.id,
          accounts_payable_id: item.id
        })
        .then(() => {
          this.editDialog = true;
          this.$refs.editDialog.fillEditForm();
        });
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
