<template>
  <v-dialog
    v-bind="$attrs"
    ref="dialog"
    persistent
    width="800"
    content-class="z-index-5"
  >
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <CardHeader>
        <template slot="left">
          <div class="pa-5 font-18px">
            {{ $t("page_cashbook_form_title") }}
          </div>
        </template>
      </CardHeader>
      <v-card-text>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="submit" lazy-validation autocomplete="off">
            <!-- the radio buttons -->
            <v-container>
              <v-row>
                <v-col offset="2" cols="4">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="withdrawal_or_deposit"
                  >
                    <v-radio-group
                      row
                      dense
                      v-model="form.withdrawal_or_deposit"
                      :error-messages="errors"
                      hide-details="auto"
                    >
                      <v-radio
                        :label="$t('page_cashbook_create_form_payment')"
                        :value="2"
                      ></v-radio>
                      <v-radio
                        :label="$t('page_cashbook_create_form_withdrawl')"
                        :value="1"
                      ></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_accrual_date')"
                  :cols="!isWithdrawal ? 12 : 6"
                >
                  <DatePicker
                    v-model="form.accrual_date"
                    @input="formatAccuralDate"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="font-12px"
                        v-on="on"
                        v-bind="attrs"
                        v-model="actual_accural_date"
                        append-icon="mdi-menu-down"
                        readonly
                        hide-details="auto"
                        dense
                      >
                        <template v-slot:append>
                          <div
                            v-on="on"
                            v-bind="attrs"
                            class="v-input__append-inner"
                          >
                            <div class="v-input__icon v-input__icon--append">
                              <i
                                aria-hidden="true"
                                class="v-icon notranslate mdi mdi-menu-down theme--light"
                              ></i>
                            </div>
                          </div>
                        </template>
                      </v-text-field>
                    </template>
                  </DatePicker>
                </CreateFormLabel>

                <template v-if="isWithdrawal">
                  <CreateFormLabel
                    :label="$t('page_cashbook_create_form_user')"
                  >
                    <v-select
                      class="font-12px"
                      :items="payers"
                      v-model="form.payer"
                      return-object
                      hide-details="auto"
                      dense
                    ></v-select>
                  </CreateFormLabel>

                  <CreateFormLabel
                    :label="$t('page_cashbook_create_form_suppliers')"
                  >
                    <v-select
                      class="font-12px"
                      v-model="form.partner_id"
                      :items="suppliers"
                      hide-details="auto"
                      dense
                    ></v-select>
                  </CreateFormLabel>

                  <CreateFormLabel
                    :label="$t('page_cashbook_create_form_sorting')"
                  >
                    <v-select
                      class="font-12px"
                      v-model="form.surveyed_subjects"
                      :items="surveyedSubjectsWithIndex"
                      hide-details="auto"
                      dense
                    ></v-select>
                  </CreateFormLabel>
                </template>

                <!-- deposit / withdrawl -->
                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_deposit')"
                  :cols="!isWithdrawal ? 12 : 6"
                >
                  <InputCurrency
                    class="font-12px"
                    v-model.number="form.purchase_amount"
                    inputmode="tel"
                    type="number"
                    hide-details="auto"
                    dense
                  ></InputCurrency>
                </CreateFormLabel>

                <CreateFormLabel
                  v-if="isWithdrawal"
                  :label="$t('page_cashbook_create_form_tax_division')"
                >
                  <v-radio-group
                    row
                    v-model="form.taxonomy"
                    hide-details="auto"
                    dense
                    class="mt-0"
                  >
                    <v-radio label="8%" :value="2"></v-radio>
                    <v-radio label="10%" :value="1"></v-radio>
                  </v-radio-group>
                </CreateFormLabel>

                <CreateFormLabel
                  :label="$t('page_cashbook_create_form_memo')"
                  :cols="12"
                >
                  <v-textarea
                    v-model="form.notes"
                    class="font-14px pa-0"
                    rows="2"
                    hide-details="auto"
                    dense
                  ></v-textarea>
                </CreateFormLabel>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions class="pb-12">
            <template v-if="editMode">
              <v-btn color="#790000" text @click="deleteConfirmation">
                削除
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2 text-light-grey"
              @click="$emit('close-dialog')"
              width="101px"
              height="31px"
              depressed
              color="transparent"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mx-2"
              width="101px"
              height="31px"
              color="primary"
              @click="submit"
              :disabled="invalid"
              :loading="loading"
            >
              {{
                editMode
                  ? $t("keep")
                  : $t("page_cashbook_confirmation_register")
              }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
import CreateFormLabel from "@/components/admin/ui/FormLabel";
import DatePicker from "@/components/admin/ui/DatePicker";
import InputCurrency from "@/components/admin/ui/Input/InputCurrency";
import dayjs from "@/plugins/dayjs";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CashbookCreateDialog",
  components: {
    CardHeader,
    CreateFormLabel,
    DatePicker,
    InputCurrency
  },
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "getShop",
      "getSuppliers",
      "getCashbook",
      "getMasterData"
    ]),
    editMode() {
      return this.edit;
    },
    isWithdrawal() {
      return this.form.withdrawal_or_deposit === 1;
    },
    suppliers() {
      return this.getSuppliers.map(supplier => {
        return {
          value: supplier.id,
          text: supplier.display_name
        };
      });
    },
    payers() {
      let MPUsers =
        this.getShop.user_shop.filter(user => user.user_role !== "S") || [];
      let SUsers = this.getShop.shared_account?.shared_accounts || [];
      return [...MPUsers, ...SUsers].map(user => {
        return {
          value: user.id,
          text: user?.user_role
            ? `${user.user?.surname} ${user.user?.name}`
            : `${user.name}`,
          role: user.user_role || "S"
        };
      });
    },
    surveyedSubjectsWithIndex() {
      return this.getMasterData.surveyed_subjects.map((item, index) => {
        return {
          value: index,
          text: item.value
        };
      });
    }
  },
  data() {
    return {
      loading: false,
      // thing bounded to the input element of the date picker YYYY年MM月DD日
      actual_accural_date: null,
      form: {
        withdrawal_or_deposit: 1,
        // the thing that is bounded to the date picker YYYY-MM-DD
        accrual_date: null,
        payer: null,
        partner_id: null,
        surveyed_subjects: null,
        purchase_amount: 0,
        taxonomy: 2,
        notes: ""
      },
      withdrawalForm: [
        "withdrawal_or_deposit",
        "accrual_date",
        "purchase_amount",
        "notes"
      ]
    };
  },
  created() {
    this.$store.dispatch("SUPPLIER_GET_ALL", { shop_id: this.getShop.id });
  },
  methods: {
    ...mapMutations([
      "showModal",
      "hideModal",
      "startModalLoading",
      "stopModalLoading"
    ]),
    formatAccuralDate(input) {
      this.actual_accural_date = dayjs(input).format("YYYY年MM月DD日");
    },
    resetForm() {
      this.form = {
        withdrawal_or_deposit: 1,
        accrual_date: dayjs().format("YYYY-MM-DD"),
        payer: null,
        partner_id: null,
        surveyed_subjects: null,
        purchase_amount: 0,
        taxonomy: 2,
        notes: ""
      };
    },
    fillEditForm() {
      if (this.editMode) {
        for (const [index] of Object.entries(this.form)) {
          if (this.getCashbook.hasOwnProperty(index)) {
            this.form[index] = this.getCashbook[index];
          }
        }
        this.actual_accural_date = dayjs(this.form.accrual_date).format(
          "YYYY年MM月DD日"
        );
        let payer = this.getCashbook.payer_m_p || this.getCashbook.payer_s;
        this.form.payer = this.payers.find(item => item.value === payer);
        this.form.surveyed_subjects = parseInt(this.form.surveyed_subjects);
      }
    },
    deleteConfirmation() {
      this.showModal({
        title: "",
        text: "現金出納情報を\n削除してもよろしいですか？",
        action: {
          process: {
            color: "primary",
            text: "削除"
          },
          cancel: {
            color: "secondary",
            text: "キャンセル"
          }
        },
        func: this.processDelete
      });
    },
    processDelete() {
      this.$store
        .dispatch("CASHBOOK_DELETE", {
          shop_id: this.getShop.id,
          cash_flow_id: this.getCashbook.id
        })
        .then(() => {
          this.hideModal();
          this.stopModalLoading();
          this.$emit("close-dialog");
          this.$emit("reload-items");
        })
        .catch(() => {
          this.stopModalLoading();
        });
    },
    submit() {
      this.$refs.observer.validate().then(async success => {
        if (!success) {
          return;
        }
        this.loading = true;

        let params = {};
        let shop_id = this.getShop.id;
        if (this.form.withdrawal_or_deposit === 2) {
          this.withdrawalForm.forEach(index => {
            params[index] = this.form[index];
          });
        } else {
          for (const [index, value] of Object.entries(this.form)) {
            params[index] = value;
          }
          params.payer_id = this.form.payer.value;
          params.payer_role = this.form.payer.role;
        }
        params.user_shop_id = this.user.id;
        await this.$store
          .dispatch(this.editMode ? "CASHBOOK_UPDATE" : "CASHBOOK_REGISTER", {
            params: params,
            shop_id: shop_id,
            ...(this.editMode ? { cash_flow_id: this.getCashbook.id } : {})
          })
          .then(() => {
            this.$emit("reload-items");
            this.resetForm();
            this.$emit("close-dialog");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
